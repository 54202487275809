import {
  addScriptContentToHead,
  addScriptToHead,
  createScriptElementContent,
  getElementById,
} from 'BaxterScript/helper/browser/Html';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { Config } from 'BaxterScript/types/Config';
import { OptanonCategory } from 'BaxterScript/version/web/config/OptanonCategory';

export const webpackExclude = (config: Config): boolean => !config.app?.sati?.enabled;

const hasPixel = () => document.getElementById('AdSense');

const addPixel = () => {
  try {
    console.info('[SLOTS][SATI][ADDPIXEL]');
    if (!hasPixel()) {
      const content = `
        var ad = document.createElement('div');
        ad.id = 'AdSense';
        ad.className = 'AdSense baxter-container';
        ad.style.display = 'block';
        ad.style.position = 'absolute';
        ad.style.top = '-1px';
        ad.style.height = '1px';
        document.body.prepend(ad);
    `;
      const script = createScriptElementContent('baxter-script-sati-pixel', content);
      document.body.prepend(script);
    }
  } catch (e) {
    console.error('[SLOTS][SATI][ADDPIXEL]', e);
    newRelicMetrics.reportError(NewRelicError.SATI_ADD_PIXEL_ERROR, { message: (e as Error).message });
  }
};

const init = () => {
  try {
    const satiInvokeScriptId = 'baxter-script-sati-invoke';
    if (!getElementById(satiInvokeScriptId)) {
      console.info('[SLOTS][SATI][INIT]');
      const { queryId } = globalThis.Baxter.config.app?.sati || {};
      const content = `
        cX.callQueue.push(['invoke', function () {
            let isAdBlockDetected = !document.getElementById('AdSense').clientHeight ? 'Yes' : 'No';
            cX.setEventAttributes({origin: 'brt-adblock', persistedQueryId: '${queryId}'});
            cX.sendEvent('adBlockerDetection', {adBlockerDetected: isAdBlockDetected});
            document.getElementById('status').innerHTML = isAdBlockDetected;
        }]);
    `;
      const script = createScriptElementContent('baxter-script-sati-invoke', content);
      document.body.appendChild(script);
    }
  } catch (e) {
    console.error('[SLOTS][SATI][INIT]', e);
    newRelicMetrics.reportError(NewRelicError.SATI_INIT_ERROR, { message: (e as Error).message });
  }
};

const callQueueContent = (siteId) =>
  `
    window.cX= window.cX|| {};
    cX.callQueue= cX.callQueue|| [];
    cX.callQueue.push(['setSiteId', '${siteId}']);
   `;

const cxContent = () =>
  `
    (function(d,s,e,t){e=d.createElement(s);e.type='text/java'+s;e.setAttribute('class','${OptanonCategory.C0002}');e.async='async';
    e.src='http'+('https:'===location.protocol?'s://s':'://')+'cdn.cxense.com/cx.js';
    t=d.getElementsByTagName(s)[0];t.parentNode.insertBefore(e,t);})(document,'script');
    `;

const metaTags = (pathName) => {
  const tags = [
    {
      id: 'baxter-script-sati-meta-pageclass',
      name: 'cXenseParse:pageclass',
      content: pathName.startsWith('/d') ? 'article' : 'frontpage',
    },
    {
      id: 'baxter-script-sati-meta-url',
      name: 'cXenseParse:url',
      content: document.location.href,
    },
  ];

  for (const tag of tags) {
    const metatag = getElementById(tag.id);
    if (!metatag) {
      const meta = document.createElement('meta');
      meta.name = tag.name;
      meta.content = tag.content;
      meta.id = tag.id;
      document.head.appendChild(meta);
    } else {
      (metatag as HTMLMetaElement).content = tag.content;
    }
  }
};

const spaContent = (siteId) =>
  `
    function SATI_TrackView(locationURL, referrerURL) {
        if(locationURL === referrerURL) {return;} window.cX = window.cX || {};
        cX.callQueue = cX.callQueue || [];
        if (typeof cX.callQueue.push === 'function') {
            cX.callQueue.push(['initializePage']);
            cX.callQueue.push(['setSiteId', '${siteId}']);
            cX.callQueue.push(['sendPageViewEvent', { 'location': locationURL, 'referrer':referrerURL}]);
        }
    };
    `;

const trackView = () => {
  console.info('[SLOTS][SATI][TRACKVIEW]');
  if (typeof globalThis.SATI_TrackView !== 'undefined') {
    globalThis.SATI_TrackView(document.location.href, document.referrer);
  }
};

export const bootstrap = () => {
  const { siteId, excludePaths, scriptPath } = globalThis.Baxter.config.app?.sati || {};
  const { pathname } = globalThis.location;
  const satiUrl = '//code3.adtlgc.com/js/sati_init.js';
  const noSurveyUrl = '//code3.adtlgc.com/js/sati_init_no_survey.js';
  for (const path of excludePaths) {
    if (pathname.startsWith(path)) {
      addScriptToHead(`baxter-script-sati-nosurvey`, false, OptanonCategory.C0002, noSurveyUrl);
    }
  }
  addScriptContentToHead('baxter-script-sati-callQueue', callQueueContent(siteId));
  addScriptToHead(`baxter-script-sati-${siteId}`, false, OptanonCategory.C0002, scriptPath || satiUrl, {
    type: 'text/javascript',
  });
  addScriptContentToHead('baxter-script-sati-cx', cxContent());
  addScriptContentToHead('baxter-script-sati-ajax', spaContent(siteId));
  metaTags(pathname);

  if (/complete|interactive|loaded/.test(document.readyState)) {
    addPixel();
    init();
  } else {
    globalThis.addEventListener('DOMContentLoaded', addPixel);
    globalThis.addEventListener('DOMContentLoaded', init);
  }
};

// eslint-disable-next-line import/no-default-export
export default {
  bootstrap,
  trackView,
};
